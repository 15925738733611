import { reportWebVitals } from 'src/helpers/reportWebVitals';
import { reportGoogleTranslate } from 'src/utils/reportGoogleTranslate/reportGoogleTranslate';
import { renderReactWithStyledComponents } from '../helpers/renderComponent';
import ModernMobileHomeApp from '../routes/ModernMobileHome';

import '../styles/pages/modern_mobile_home_page.scss';

export default renderReactWithStyledComponents(
  'ModernMobileHomeApp',
  ModernMobileHomeApp,
);

reportWebVitals();

reportGoogleTranslate();
